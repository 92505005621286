<template>
  <div class="register">
    <Header />
    <div class="register-header">
      <b-container class="register-header-container">
        <b-row class="register-header-row">
          <div class="col-lg-12 col-md-6">
            <div class="register-header">
              <h1>Formulir Edit Profil Risiko</h1>
              <p>Mohon isi data berikut agar Anda bisa memulai investasi.</p>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>
    <div class="register-form">
      <b-container class="register-form-container">
        <b-form @submit.prevent="handleSubmit">
          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6">
              <div class="register-header text-left">
                <h1>Profil Risiko</h1>
                <p>Risk Profile</p>
              </div>
            </div>
            <div class="col-lg-9 col-md-6">
              <div class="ekyc-form-input">
                <h3>
                  Apa yang menjadi tujuan keuangan Anda? (Boleh memilih lebih
                  dari satu)
                </h3>
                <p>
                  What is your financial goal(s)? (You can choose more than one
                  option)
                </p>
                <b-row>
                  <div class="col-lg-6 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-checkbox
                        v-model="invest_goal"
                        vs-value="Pendapatan berkala"
                        ><h4 class="mt-2">
                          Pendapatan berkala<span>/ Regular income</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-checkbox
                        v-model="invest_goal"
                        vs-value="Pertumbuhan kekayaan"
                        ><h4 class="mt-2">
                          Pertumbuhan kekayaan
                          <span>/ Wealth accumulation</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-checkbox
                        v-model="invest_goal"
                        vs-value="Persiapan dana pendidikan"
                        ><h4 class="mt-2">
                          Persiapan dana pendidikan<span>/ Education</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-">
                    <div class="ekyc-form-radio">
                      <vs-checkbox
                        v-model="invest_goal"
                        vs-value="Perlindungan modal"
                        ><h4 class="mt-2">
                          Perlindungan modal <span>/ Capital protection</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-">
                    <div class="ekyc-form-radio">
                      <vs-checkbox
                        v-model="invest_goal"
                        vs-value="Persiapan dana pensiun"
                        ><h4 class="mt-2">
                          Persiapan dana pensiun
                          <span>/ Retirement purpose</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-">
                    <div class="ekyc-form-radio">
                      <vs-checkbox v-model="invest_goal" vs-value="Lainnya"
                        ><h4 class="mt-2">
                          Lainnya <span>/ Others</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                </b-row>
                <div class="error mt-3" v-if="!$v.invest_goal.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>
          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6"></div>
            <div class="col-lg-9 col-md-6">
              <div class="ekyc-form-input">
                <h3>
                  Berdasarkan tujuan keuangan Anda, berapa jangka waktu
                  investasi Anda?
                </h3>
                <p>
                  According to your financial goal(s). how long is your
                  investment term?
                </p>
                <b-row>
                  <div class="col-lg-3 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_period"
                        vs-name="invest_period"
                        vs-value="3"
                        ><h4 class="mt-2">
                          &#60; 1 tahun<span>/ year</span>
                        </h4></vs-radio
                      >
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_period"
                        vs-name="invest_period"
                        vs-value="5"
                        ><h4 class="mt-2">
                          1 - &#60; 3 tahun <span>/ year</span>
                        </h4></vs-radio
                      >
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_period"
                        vs-name="invest_period"
                        vs-value="6"
                        ><h4 class="mt-2">
                          3 - 5 tahun<span>/ year</span>
                        </h4></vs-radio
                      >
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_period"
                        vs-name="invest_period"
                        vs-value="7"
                        ><h4 class="mt-2">
                          &#62; 5 tahun <span>/ year</span>
                        </h4></vs-radio
                      >
                    </div>
                  </div>
                </b-row>
                <div class="error mt-3" v-if="!$v.invest_period.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>

          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6"></div>
            <div class="col-lg-9 col-md-6">
              <div class="ekyc-form-input">
                <h3>
                  Menurut Anda, bagaimana pengetahuan atau pengalaman investasi
                  Anda?
                </h3>
                <p>How is your knowledge or experience in investment?</p>
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_experience"
                        vs-name="invest_experience"
                        vs-value="3"
                      >
                        <ol class="ml-4" type="a" start="1">
                          <li>
                            <h4>
                              Saya/Kami tidak memiliki pengetahuan atau
                              pengalaman dalam investasi
                            </h4>
                            <p>
                              I/We do not have any knowledge or experience in
                              investment
                            </p>
                          </li>
                        </ol>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_experience"
                        vs-name="invest_experience"
                        vs-value="5"
                      >
                        <ol class="ml-4" type="a" start="2">
                          <li>
                            <h4>
                              Saya/Kami memiliki pengetahuan atau pengalaman
                              dengan reksa dana
                            </h4>
                            <p>
                              I/We do have knowledge or experience in mutual
                              funds
                            </p>
                          </li>
                        </ol>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_experience"
                        vs-name="invest_experience"
                        vs-value="6"
                      >
                        <ol class="ml-4" type="a" start="3">
                          <li>
                            <h4>
                              Selain produk yang disebutkan di poin 3b,
                              Saya/Kami memiliki pengetahuan atau pengalaman
                              dengan beberapa atau semua produk berikut:
                              Obligasi, Reksa Dana Pasar Uang, Reksa Dana
                              Terproteksi, Reksa Dana Pendapatan tetap
                            </h4>
                            <p>
                              In addition to the products as set out in point
                              3b, l/We do have knowledge or experience in
                              several or all of these products; bonds, money
                              market fund, capital protected fund, fixed income
                              fund
                            </p>
                          </li>
                        </ol>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_experience"
                        vs-name="invest_experience"
                        vs-value="7"
                      >
                        <ol class="ml-4" type="a" start="4">
                          <li>
                            <h4>
                              Selain produk yang disebutkan di poin 3b dan 3c,
                              Saya/Kami memiliki pengetahuan atau pengalaman
                              dengan beberapa atau semua produk berikut: saham,
                              reksa dana campuran, reksa dana saham, derivative,
                              produk terstruktur
                            </h4>
                            <p>
                              In addition to the products as set out in points
                              3b and 3c. l/We do have knowledge or experience in
                              several or all of these products: equity, balanced
                              fund, equity fund, derivatives, structured
                              products
                            </p>
                          </li>
                        </ol>
                      </vs-radio>
                    </div>
                  </div>
                </b-row>
                <div class="error mt-3" v-if="!$v.invest_experience.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>
          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6"></div>
            <div class="col-lg-9 col-md-6">
              <div class="ekyc-form-input">
                <h3>
                  Jenis investasi apa yang pemah dan/atau masih Anda miliki?
                  (Boleh memilih lebih dari satu)
                </h3>
                <p>
                  What kind of investment products that you ever had and/or you
                  currently hold? (You can choose more than one option)
                </p>
                <b-row>
                  <div class="col-lg-6 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-checkbox v-model="invest_asset" vs-value="Deposito"
                        ><h4 class="mt-2">
                          Deposito<span>/ Deposit</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-checkbox
                        v-model="invest_asset"
                        vs-value="Reksa Dana Terproteksi"
                        ><h4 class="mt-2">
                          Reksa Dana Terproteksi
                          <span>/ Capital Protected Fund</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-checkbox
                        v-model="invest_asset"
                        vs-value="Reksa Dana Terbuka"
                        ><h4 class="mt-2">
                          Reksa Dana Terbuka<span>/ Open End Fund</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-">
                    <div class="ekyc-form-radio">
                      <vs-checkbox v-model="invest_asset" vs-value="Obligasi"
                        ><h4 class="mt-2">
                          Obligasi <span>/ Bonds</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-">
                    <div class="ekyc-form-radio">
                      <vs-checkbox v-model="invest_asset" vs-value="Saham"
                        ><h4 class="mt-2">
                          Saham <span>/ Equities</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-">
                    <div class="ekyc-form-radio">
                      <vs-checkbox v-model="invest_asset" vs-value="Derivatif"
                        ><h4 class="mt-2">
                          Derivatif<span>/ Dervatives</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-">
                    <div class="ekyc-form-radio">
                      <vs-checkbox
                        v-model="invest_asset"
                        vs-value="Produk Terstruktur"
                        ><h4 class="mt-2">
                          Produk Terstruktur <span>/ Structured Products</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-">
                    <div class="ekyc-form-radio">
                      <vs-checkbox v-model="invest_asset" vs-value="Lainnya"
                        ><h4 class="mt-2">
                          Lainnya<span>/ Others</span>
                        </h4></vs-checkbox
                      >
                    </div>
                  </div>
                </b-row>
                <div class="error mt-3" v-if="!$v.invest_asset.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>

          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6"></div>
            <div class="col-lg-9 col-md-6">
              <div class="ekyc-form-input">
                <h3>
                  Berapa persen dari total asset anda yang dialokasikan untuk
                  investasi?
                </h3>
                <p>
                  How many percentage of your total assets do you allocate for
                  investment?
                </p>
                <b-row>
                  <div class="col-lg-3 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_alocation"
                        vs-name="invest_alocation"
                        vs-value="4"
                        ><h4 class="mt-2">&#60; 25%</h4></vs-radio
                      >
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_alocation"
                        vs-name="invest_alocation"
                        vs-value="5"
                        ><h4 class="mt-2">25% - &#60; 50%</h4></vs-radio
                      >
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_alocation"
                        vs-name="invest_alocation"
                        vs-value="7"
                        ><h4 class="mt-2">50% - 75%</h4></vs-radio
                      >
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_alocation"
                        vs-name="invest_alocation"
                        vs-value="8"
                        ><h4 class="mt-2">&#62; 75%</h4></vs-radio
                      >
                    </div>
                  </div>
                </b-row>
                <div class="error mt-3" v-if="!$v.invest_alocation.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>

          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6"></div>
            <div class="col-lg-9 col-md-6">
              <div class="ekyc-form-input">
                <h3>
                  Bagaimana tingkat ketergantungan Anda pada hasil investasi
                  untuk membiayai kebutuhan hidup sehari-hari?
                </h3>
                <p>
                  How is your dependency on the investment return to support
                  your daily living cost?
                </p>
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_depedency"
                        vs-name="invest_depedency"
                        vs-value="4"
                      >
                        <h4>Sangat bergantung pada hasil investasi</h4>
                        <p>Highly dependent on the investment return</p>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_depedency"
                        vs-name="invest_depedency"
                        vs-value="5"
                      >
                        <h4>Bergantung pada hasil investasi</h4>
                        <p>Quite Dependent on the investment return</p>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_depedency"
                        vs-name="invest_depedency"
                        vs-value="6"
                      >
                        <h4>Sedikit bergantung pada hasil investasi</h4>
                        <p>Less dependent on the investment return</p>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_depedency"
                        vs-name="invest_depedency"
                        vs-value="8"
                      >
                        <h4>
                          Tidak bergantung pada hasil investasi, minimum sampai
                          5 tahun ke depan
                        </h4>
                        <p>
                          Not dependent on the investment return, at least for
                          next 5 years
                        </p>
                      </vs-radio>
                    </div>
                  </div>
                </b-row>
                <div class="error mt-3" v-if="!$v.invest_depedency.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>

          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6"></div>
            <div class="col-lg-9 col-md-6">
              <div class="ekyc-form-input">
                <h3>
                  Dalam kinerja pasar yang kurang baik, pernyataan mana yang
                  paling menggambarkan ukuran risiko yang dapat diterima oleh
                  Anda dalam berinvestasi?
                </h3>
                <p>
                  In an unfavourable market condition, which statement best
                  describes the risk level that still acceptable for your
                  investment?
                </p>
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_risk"
                        vs-name="invest_risk"
                        vs-value="4"
                        required
                      >
                        <h4>
                          Saya/Kami hanya bersedia untuk berinvestasi pada
                          produk dengan minimum pengembalian pokok investasi
                        </h4>
                        <p>
                          I / We are only willing to invest in a product with
                          capital protection
                        </p>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_risk"
                        vs-name="invest_risk"
                        vs-value="5"
                      >
                        <h4>
                          Saya/Kami bersedia untuk menerima penurunan di bawah
                          10% dari pokok investasi
                        </h4>
                        <p>
                          I / We still can tolerate less than 10% decline from
                          the investment principal
                        </p>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_risk"
                        vs-name="invest_risk"
                        vs-value="6"
                      >
                        <h4>
                          Saya/Kami bersedia untuk menerima penurunan sebesar
                          10% sampai dengan dibawah 25% dari pokok investasi
                        </h4>
                        <p>
                          I / We still can tolerate 10% to 25% deline from the
                          investment principal
                        </p>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_risk"
                        vs-name="invest_risk"
                        vs-value="7"
                      >
                        <h4>
                          Saya/Kami bersedia untuk menerima penurunan sebesar
                          25% sampai dengan 50% dari pokok investasi
                        </h4>
                        <p>
                          I / We still can tolerate 25% to 50% decline from the
                          investment principal
                        </p>
                      </vs-radio>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="ekyc-form-radio">
                      <vs-radio
                        v-model="invest_risk"
                        vs-name="invest_risk"
                        vs-value="8"
                      >
                        <h4>
                          Saya/Kami bersedia untuk menerima penurunan diatas 50%
                          dari pokok investasi
                        </h4>
                        <p>
                          I / We still can tolerate more than 50% decline from
                          the investment principal
                        </p>
                      </vs-radio>
                    </div>
                  </div>
                </b-row>
                <div class="error mt-3" v-if="!$v.invest_risk.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>
          <!-- <b-form-file v-model="file2" class="mt-3" plain></b-form-file>
          <div class="mt-3">Selected file: {{ file2 ? file2.name : "" }}</div> -->
          <b-row class="justify-content-md-end btn-submit-row mb-4">
            <b-button
              type="submit"
              :disabled="
                loading ||
                !invest_goal ||
                !invest_period ||
                !invest_experience ||
                !invest_asset ||
                !invest_alocation ||
                !invest_depedency ||
                !invest_risk
              "
              @click.once="handleSubmit()"
              class="btn-tertiary px-4 py-2"
            >
              <div v-if="loading">
                <div class="spinner-border spinner-border-sm"></div>
                Loading
              </div>
              <div v-else>Submit</div>
            </b-button>
          </b-row>
        </b-form>

        <div class="modal-risk-profile">
          <b-modal
            id="modal-success-risk-profile"
            v-model="successShow"
            size="md"
            class="modal-success-risk-profile"
            centered
            hide-footer
            hide-header
            no-close-on-backdrop
          >
            <div class="d-block text-center">
              <img
                class="pt-4"
                src="@/assets/img/icons/transactions/checked-blue.svg"
                alt=""
              />
              <h1 class="header-modal p-4 my-2 text-dark">
                Yay! <br />
                Anda telah berhasil mengupdate data profil risiko
              </h1>
              <b-row class="justify-content-md-end btn-submit-row mb-4">
                <div class="col-lg-12 col-md-6">
                  <b-button
                    size="sm"
                    block
                    class="btn-tertiary px-4 py-2"
                    href="/profile"
                    >Lihat Profil</b-button
                  >
                </div>
              </b-row>
            </div>
          </b-modal>

          <b-modal
            id="modal-success-risk-profile"
            v-model="failedShow"
            size="lg"
            class="modal-success-risk-profile"
            centered
            hide-footer
          >
            <div class="d-block text-center">
              <img
                src="@/assets/img/ekyc/data-not-correct.svg"
                alt="data-not-correct"
              />
              <h1 class="p-4 my-2 text-dark">Isian belum sesuai</h1>
              <p>
                Cek kembali isian Anda.<br />Atau hubungi Customer Service Kami.
              </p>
            </div>
          </b-modal>
        </div>
      </b-container>
      <div class="modal-register"></div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import axios from "axios";

import { required } from "vuelidate/lib/validators";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "ChangeRiskProfile",
  metaInfo: {
    title: "Ganti Profil Risiko | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  validations: {
    invest_goal: {
      required,
    },
    invest_period: {
      required,
    },
    invest_experience: {
      required,
    },
    invest_asset: {
      required,
    },
    invest_alocation: {
      required,
    },
    invest_depedency: {
      required,
    },
    invest_risk: {
      required,
    },
    investor_risk_profile: {
      required,
    },
  },
  data() {
    return {
      invest_goal: [],
      invest_period: "",
      invest_experience: "",
      invest_asset: [],
      invest_alocation: "",
      invest_depedency: "",
      invest_risk: "",
      investor_risk_profile: "",
      successShow: false,
      failedShow: false,
      risk_profile: false,
      loading: false,
      processing: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      app_url: "app_url",
      check: "auth/check",
      user: "auth/user",
    }),
  },
  methods: {
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        // let questionA = this.invest_goal;
        let questionB = this.invest_period;
        let questionC = this.invest_experience;
        // let questionD = this.invest_asset;
        let questionE = this.invest_alocation;
        let questionF = this.invest_depedency;
        let questionG = this.invest_risk;

        let score =
          parseInt(questionB) +
          parseInt(questionC) +
          parseInt(questionE) +
          parseInt(questionF) +
          parseInt(questionG);
        let totalScore;

        if (score < 24) {
          totalScore = "Low";
        } else if (score < 32) {
          totalScore = "Moderate";
        } else {
          totalScore = "High";
        }

        let investor_risk_profile = totalScore;

        this.risk_profile = investor_risk_profile;

        let formData = new FormData();
        formData.append("investors_risk_profile", this.risk_profile);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${this.bearer_token}`,
          },
        };

        instance
          .post(
            `${this.app_url}update-individual-risk-profile`,
            formData,
            axiosConfig
          )
          .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);

            this.successShow = true;

            this.loading = !false;
            this.processing = false;
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.failedShow = true;
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-header-container {
  padding-top: 120px;
  padding-bottom: 40px;
}

.register-header-row {
  text-align: left !important;
}

.register-form {
  background: #f3f4f6;
}
.register-form-container {
  padding-top: 40px;
  padding-bottom: 60px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #4b5563;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #4b5563;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #4f566b;
}

.con-vs-checkbox {
  justify-content: start;
  margin: 0;
}

.con-vs-radio {
  justify-content: start;
  align-items: baseline;
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #e53e3e !important;
  }
}

.register-header h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  color: #111827;
}

.register-header p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #4b5563;
}

.ekyc-form-input h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #1f2937;
}

.ekyc-form-input p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #9ca3af;
}

.ekyc-form-input span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #9ca3af;
}

.ekyc-form-radio span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: #6b7280;
}

.ekyc-form-radio h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #374151;
}

.ekyc-form-radio p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #9ca3af;
}

.ekyc-form-fatcha p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #374151;
}

.residental-address-checkbox h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #374151;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.sma {
  margin-left: -71px;
}

.jumbotron {
  padding: 0;
  background-color: white;
  opacity: 1;
}

.modal-risk-profile {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    text-align: center;
    color: black;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    text-align: center;
    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  .register-header-row {
    text-align: center !important;
  }
  .sma {
    margin-left: 0px;
  }
  .btn-submit-row {
    justify-content: center !important;
  }
  .header-modal {
    font-size: 16px !important;
  }
}
</style>